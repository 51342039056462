import {Observable, of, ReplaySubject} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {EventEmitter, Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {NotificationService} from './notification.service';
import {LocalStorageService} from './local-storage.service';
import {LoginSessionService} from './login-session.service';
import {isPlatformBrowser} from '@angular/common';
import {LoginResponseApiModel, LoginService} from "api-client";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

    user: string;
    private rightsDefaults = {
        changePlan: true,
        changePodioAccount: true,
        changePassword: true,
        changeEmailNotification: true,
        viewAdminPage: false
    };
    private rights = {...this.rightsDefaults};
    loading = false;

    storageName = 'podio_backup_login_session';

    loginUpdated = new EventEmitter<boolean>();

    redirectUrl = new ReplaySubject<string>(1);
    currentRedirectUrl: string;

    constructor(private loginService: LoginService,
                private notify: NotificationService,
                private router: Router,
                private localStorage: LocalStorageService,
                private loginSessionService: LoginSessionService,
                @Inject(PLATFORM_ID) private platformId: Object) {

        this.redirectUrl.next('/view/account');
        this.redirectUrl.subscribe(newUrl => this.currentRedirectUrl = newUrl);

        if (isPlatformBrowser(this.platformId)) {
          // router.url is not correctly initialized yet..
          const queryParamMap = router.parseUrl(window.location.pathname + window.location.search).queryParamMap;
          // TODO clear search/query without annoying angular.. (Prio B, as automatically cleared on first link click..)
          // window.location.search = '';
          const oauthCode = queryParamMap.get('code');
          if (oauthCode) {
            this.loading = true;
            console.log('oauth code: ', oauthCode);
            console.log('users: ', queryParamMap.get('users'));

            if (queryParamMap.get('users')) {
              this.notify.choose('Multiple users found!', 'Please select login:', queryParamMap.get('users').split(','))
                .then(result => {
                  console.log('selected user: ', result);
                  this.loginOauth(oauthCode, result);
                });
            } else {
              this.loginOauth(oauthCode);
            }

          } else {
            console.log('loginsession from localStorage: ', this.localStorage.get(this.storageName));
            if (this.localStorage.get(this.storageName) !== null) {
              this.loginSessionService.loginSession = this.localStorage.get(this.storageName);
              this.loading = true;
              this.loginService.login().pipe(
                map(response => this.mapLoginResponse(response, true)))
                .subscribe(() => {
                  console.log('logged in via login session.');
                }, error => {
                  this.notify.notify(error.message, 'Could not log in!', 'warning');
                  this.clear();
                });
            }
          }
        }
    }

    /**
     * After this url is called, after any login, user will be redirected to given url.
     * (Except fixed redirects, e.g. register..)
     * @param url
     */
    fixedLoginRedirect(url: string) {
        this.redirectUrl.next(url);
    }

    private loginOauth(oauthCode: string, login: string = null) {
        this.loginService.login('Bearer ' + oauthCode, login ? btoa(login) : null).pipe(
            map(response => this.mapLoginResponse(response, true)))
            .subscribe(() => {
                console.log('logged in via oauth.');
            }, error => {
                this.notify.notify(error.message, 'Could not log in!', 'warning');
                this.clear();
            });
    }

    login(username: string, password: string) {
        this.loading = true;
        return this.loginService.login('Basic ' + btoa(username + ':' + password)).pipe(
            map(response => this.mapLoginResponse(response, false))).toPromise();
    }

    /**
     *
     * @param loginResponse
     * @param autoLogin skips redirect and stays on current page!
     */
    public mapLoginResponse(loginResponse: LoginResponseApiModel | undefined, autoLogin: boolean) {
        // login successful if there's a jwt token in the response
        if (loginResponse && loginResponse.loginsession) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.localStorage.set(this.storageName, loginResponse.loginsession, 7, true);
            this.user = loginResponse.user;
            this.rights = {...this.rights};
            // only update rights that are defined and non-null in the responseL
            Object.keys(loginResponse.rights ?? {}).forEach(key => {
              if (loginResponse.rights[key] !== null) {
                this.rights[key] = loginResponse.rights[key];
              }
            });
            this.loginSessionService.loginSession = loginResponse.loginsession;
            this.loading = false;
            console.log('login successful: ' + this.localStorage.get(this.storageName));

            if (!autoLogin && this.currentRedirectUrl) {
                //noinspection JSIgnoredPromiseFromCall
                this.router.navigateByUrl(this.currentRedirectUrl);
            }
            this.loginUpdated.emit(true);
        } else {
            this.clear();
        }
    }

    private clear() {
        this.localStorage.remove(this.storageName, true);
        this.user = null;
        this.rights = {...this.rightsDefaults};
        this.loginSessionService.loginSession = null;
        this.loading = false;
        this.loginUpdated.emit(false);
    }

    hasRight(right: string) {
        return !!this.rights[right];
    }

    isLoggedInOrLoading(): Observable<boolean> {
        if (!this.loading) {
            return of(this.isLoggedIn());
        } else {
            return this.loginUpdated.pipe(first());
        }
    }

    isLoggedIn() {
        return this.user != null;
    }

    logout() {
        this.loading = true;
        this.loginService.logout().toPromise()
            .then(result => console.log('Logged out: ', result))
            .catch(error => console.log('Logout failed: ', error));
        this.clear();
        this.router.navigateByUrl('/view/about')
    }
}
