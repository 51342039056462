import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Injectable()
export class LocalStorageService {

    private hasLocalStorage = null;

    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    }

    /**
     * Whether the current browser supports local storage as a way of storing data
     * @var {Boolean}
     */
    private _hasLocalStorageSupport() {
        if (this.hasLocalStorage === null) {
            try {
                if (!('localStorage' in window && window['localStorage'] !== null)) {
                    return false;
                }
                const storage = window['localStorage'];
                const x = '__storage_test__';
                storage.setItem(x, x);
                storage.removeItem(x);
                this.hasLocalStorage = true;
                return true;
            } catch (e) {
                this.hasLocalStorage = false;
            }
        }
        return this.hasLocalStorage;
    }

    /**
     * @param {String} name The name of the property to read from this document's cookies
     * @return {?String} The specified cookie property's value (or null if it has not been set)
     */
    private _readCookie(name) {
        if (!isPlatformBrowser(this.platformId)) {
            return null;
        }
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            // trimLeft:
            while (c.charAt(0) == ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) == 0) {
                const value = c.substring(nameEQ.length, c.length);
                return value ? value : null;
            }
        }

        return null;
    }

    /**
     * @param {String} name The name of the property to set by writing to a cookie
     * @param {String} value The value to use when setting the specified property
     * @param {int} [days] The number of days until the storage of this item expires
     */
    private _writeCookie(name, value, days) {
        const expire = days ? ';expires=' + (new Date((Date.now() + (days * 24 * 60 * 60 * 1000))).toUTCString()) : '';
        document.cookie = name + '=' + value + expire + ';path=/';
    };

    /**
     * @param {String} name The name of the property to set
     * @param {String} value The value to use when setting the specified property
     * @param {int} [days] The number of days until the storage of this item expires (if storage of the provided item must fallback to using cookies)
     * @param {boolean} both set cookie and localStorage (if available)
     */
    set(name, value, days, both = false) {
        if (this._hasLocalStorageSupport()) {
            localStorage.setItem(name, value);
            if (both) {
                this._writeCookie(name, value, days);
            }
        } else {
            this._writeCookie(name, value, days);
        }
    }

    /**
     * @param {String} name The name of the value to retrieve
     * @return {?String} The value of the
     */
    get(name) {
        return this._hasLocalStorageSupport()
            ? (localStorage.getItem(name) ? localStorage.getItem(name) : null)
            : this._readCookie(name);
    }

    /**
     * @param {String} name The name of the value to delete/remove from storage
     * @param {boolean} both delete from localStorage and cookie
     */
    remove(name, both = false) {
        if (this._hasLocalStorageSupport()) {
            localStorage.removeItem(name);
            if (both) {
                this.set(name, '', -1);
            }
        } else {
            this.set(name, '', -1);
        }
    }

}
